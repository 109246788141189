import { graphql } from "gatsby";
import PeopleBio from "../components/people-bio";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

export const query = graphql`
  query peopleTemplateQuery($id: String!) {
    post: sanityPeople(id: { eq: $id }) {
      ... fragPeoplePage
    }
    dictionary: allSanityDictionary {
      nodes {
        ... dictionaryFragment
      }
    }
  }
`;

const PeopleTemplate = (props) => {
  const { data, errors } = props;

  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)

  post.dictionary = data && data.dictionary;
  try {
    return (
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.name || "Advisers"}
            description={toPlainText(post._rawExcerpt)}
            image={post.name}
            canonical={getPageUrl}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <PeopleBio {...post} />}
      </Layout>
    );
  } catch (e) {
    console.log("PeopleTemplate", e);
    return (null);
  }

};

export default PeopleTemplate;
