import Container from "./container";
import ContainerForPages from "./containerForPages";
import PortableText from "./portableText";
import React, { useState, useEffect } from "react";
import { getNumberOfLikes, getAdviserQuestions } from "../lib/helpers";
import WidgetComponent from "./sidebarComponents/widgetComponent";
import AdviserPageReviews from "./adviserPageReviews";
import AdviserLikes from "./landingPageComponents/adviserLikes";
import AdviserLikesMobile from "./landingPageComponents/adviserLikesMobile";
import {
  AppBar,
  Grid,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  CssBaseline,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useStaticQuery, graphql } from "gatsby";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../.././client-config";
import serializers from "./serializers";
import ButtonIcon from "./icon/ArrowRightSquare.svg";
import LocationIcon from "./icon/LocationIcon.svg";
import HeartIcon from "./icon/HeartIcon.svg";
import TickYesIcon from "./icon/TickYesIcon.svg";
import ContainerForSVG from "./containerForSVG";
import BmAwards from "./icon/BmAwards.svg";
import Aberdeen from "./icon/Aberdeen.svg";
import Trophy from "./icon/Trophy.svg";
import RateAdviserDialog from "./popups/rateAdviserPopup";
import Dialog from "./popups/contactPopup";
import FooterDisclaimer from "./footer-disclaimer";
import PeopleAward from "./people-award";
import AdviserCompany from "./adviserCompany";

import FilteredQuestionsAdviser from "../components/filteredQuestions/filtered-questions-adviser";
import { Figure } from "./Figure";
import { formatRelative } from "date-fns";

const useStyles = makeStyles((theme) => ({
  linkTextNotActive: {
    color: "#50586C",
    fontSize: "14px",
    fontWeight: "500",
  },
  linkTextActive: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "600",
  },
  linkMargin: {
    marginTop: "14px",
  },
  grey: {
    paddingBottom: "30px",
    // paddingLeft: "30px",
    // paddingRight: "30px"
  },
  greyInner: {
    paddingTop: "24px",
    paddingBottom: "30px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dot: {
    color: "#B2C2C3",
    marginLeft: "7px",
    marginRight: "7px",
  },
  imageHolder: {
    // maxWidth: "216px",
    // maxHeight: "216px",
    paddingRight: "24px",
    // [theme.breakpoints.down("xs")]: {
    //   maxWidth: "72px",
    //   maxHeight: "72px",
    // },
  },
  imageContainer: {
    //background: 'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)',
    //opacity: '0.35'
    borderRadius: "10px",
  },
  profile: {
    direction: "row",
    backgroundColor: "#ffffff",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    padding: "24px",
    border: "1px solid #DBE3EF",
    borderRadius: "12px",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      padding: "16px",
    },
  },
  sidebarTitle: {
    color: "#262641",
    paddingBottom: "12px",
  },
  sidebarSubtitle: {
    color: "#3C455C",
    paddingBottom: "12px",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  sidebarText: {
    color: "#8C8CA1",
    lineHeight: "20px",
    fontWeight: "normal",
    fontSize: "14px",
  },
  sidebarText80Percent: {
    color: "#8C8CA1",
    lineHeight: "20px",
    fontWeight: "normal",
    fontSize: "14px",
    width: "88%",
  },
  iconSidebar: {
    paddingRight: "8px",
  },
  sidebarMenuItem: {
    paddingBottom: "8px",
  },
  sidebar: {
    backgroundColor: "#ffffff",
    height: "fit-content",
    padding: "20px 20px 5px 20px",
    borderRadius: "12px",
    border: "1px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    },
  },
  sideBarAwards:{
    backgroundColor: "#ffffff",
    height: "fit-content",
    padding: "1.25rem",
    borderRadius: "12px",
    border: "1px solid #DBE3EF",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
    width: "300px",
    marginTop: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      marginTop: "1rem",
      width: "100%",
    },
  },
  mobileFullwidth: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "20px",
    },
  },
  sidebarBlock: {
    border: "1px solid #DBE3EF",
    padding: "16px 16px 8px 16px",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  aboutMe: {
    marginBottom: "16px",
    marginTop: "40px",
  },
  jobTitle: {
    color: "#50586C",
    paddingBottom: "16px",
  },
  jobTitleLessPaddingBottom: {
    color: "#50586C",
    paddingBottom: "13px",
  },
  likes: {
    color: "#8C8CA2",
    marginRight: "17px",
  },
  amountOfLikes: {
    marginLeft: "7px",
  },
  likesButtonContainer: {
    width: "100%",
    marginTop: "9px",
  },
  profileText: {
    justifyContent: "space-between",
    direction: "column",
  },
  nameText: {
    marginBottom: "8px",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "47px",
    letterSpacing: "-0.72px",
    [theme.breakpoints.down("xs")]:{
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "-0.4px",
    },
  },
  contactMeButton: {
    borderRadius: "8px",
    width: "220px",
    height: "56px",
    backgroundColor: "#DBE3EF",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "28px",
    },
  },
  contactMeButtonText: {
    marginRight: "11px",
    color: "#50586C",
  },
  location: {
    marginLeft: "13px",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      marginLeft: "7px",
    },
  },
  contactRateContainer: {
    marginTop: "2.5em",
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  widgetsContainer: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  adContent: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileSixtyFive: {
    [theme.breakpoints.down("sm")]: {
      width: "65%",
    },
  },
  likesButtonContainerNoPaddingTop: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  likesButtonContainerLessPaddingTop: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  awardImgContainer: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    padding: "8px",
    marginBottom: "16px"
  },
  sponsor: {
    width: "7.75rem",
    height: "4rem"
  },
  awardIcon: {
    width: "6.125rem",
    height: "4rem"
  },
  accoladeContainer: {
    marginBottom: "20px",
    background: "#FFFFFF",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    "& ul":{
      margin: "0",
      paddingTop: "6px",
      paddingBottom: "6px",
      "& li": {
        listStyleImage: `url(${Trophy})`,
        paddingTop: "6px",
        marginBottom: "6px",
        left: "9px",
        listItem: {
          position: "relative",
          left: "9px"
        }
      },
    }
  },
  sideBarCompany: {
      backgroundColor: "#ffffff",
      height: "fit-content",
      borderRadius: "12px",
      border: "1px solid #DBE3EF",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
      width: "300px",
      marginTop: "1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "1.5rem"
      }

    },
    sideBarCompanyNoMarginTop: {
      backgroundColor: "#ffffff",
      height: "fit-content",
      borderRadius: "12px",
      border: "1px solid #DBE3EF",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgb(64 64 64 / 10%)",
      width: "300px",
      marginTop: "0",
      [theme.breakpoints.up("md")]: {
        marginTop: "0"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      // [theme.breakpoints.down("md")]: {
      //   minWidth: "335px"
      // }
    // },
    // itemsRightContainer: {
    //   minWidth: "330px",
    },
    itemsRightContainer :{
      [theme.breakpoints.down("sm")]: {
        flex: "0 0 100%"
        // maxWidth: "572px",
        // width: "572px"
      },
    },
  disclaimerFlexContainer:{
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  }
}));

let placeholders = {
  iLikeHelpingPeopleOn: [
    "Placeholder: Inheritance planning",
    "Placeholder: Pensions – I’m retired ",
    "Placeholder: Mortgages",
  ],
  qualifications: [
    "Placeholder: Diploma in Financial Planning",
    "Placeholder: PIMFA ESG Academy",
    "Placeholder: Chartered Financial Planner",
  ],
  amountOfLikes: "100",
};

function BlogPost(props) {
  const {
    email,
    name,
    jobTitle,
    averageRating,
    numberOfLikes,
    image,
    _rawBio,
    location,
    slug,
    feeStructure,
    hideFeeStructure,
    qualification,
    specialisations,
    dictionary,
    dashboardId,
    widgets,
    awards,
    awardTitle,
    awardImage,
    company,
    isVisibleAdviser
  } = props;
  let direction = "row";
  const classes = useStyles();
  const [likes, setLikes] = useState(0);
  const [questions, setQuestions] = useState([]);

  var companySlug = ""
  var folder = ""
  var fullCompanySlug = "#"

  const { companyDisplayName, companyLogo, reviewReference } = company || {}



  if ((company) && (reviewReference)){
    companySlug = reviewReference.slug.current
    folder = reviewReference.subfolder.folder
    fullCompanySlug = folder.concat(companySlug);
  }

  useEffect(() => {
    (async () => {
      var adviserQuestions = await getAdviserQuestions(dashboardId);

      if (typeof adviserQuestions !== "undefined") {
        setQuestions(adviserQuestions);
      }
    })();
  }, []);
  let mainImageWithType = image;

  if (image === null) {
    let mainImageWithType = null;
  } else {
    mainImageWithType._type = "mainImage";
    mainImageWithType.isAdviserFilteredImage = true;
  }

  return (
    <article>
      <ContainerForPages>
        <Container>
          <Grid className={classes.grey}>
            <Hidden smDown implementation="css">
              <Typography variant="subtitle2" className={classes.linkMargin}>
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  className={classes.linkTextNotActive}
                >
                  Home
                </Link>
                <span className={classes.dot}>{" • "}</span>
                <Link
                  to="/advice"
                  style={{ textDecoration: "none" }}
                  className={classes.linkTextNotActive}
                >
                  Advice
                </Link>
                <span className={classes.dot}>{" • "}</span>
                <Link
                  to={`/advisers`}
                  style={{ textDecoration: "none" }}
                  className={classes.linkTextNotActive}
                >
                  Advisers
                </Link>
              </Typography>
            </Hidden>

            <Grid container className={classes.greyInner} direction={direction}>
              <Grid item className={classes.content}>
                <Grid container className={classes.profile}>
                  <Grid xs={4} item className={classes.imageHolder}>
                    <BasePortableText
                      className={classes.imageContainer}
                      blocks={mainImageWithType}
                      serializers={serializers}
                      {...clientConfig.sanity}
                    />
                  </Grid>
                  <Grid xs={8} container item className={classes.profileText}>
                    <Grid>
                      <Typography variant="h1" className={classes.nameText}>
                        {name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          averageRating != undefined &&
                          averageRating != null &&
                          averageRating > 0
                            ? classes.jobTitleLessPaddingBottom
                            : classes.jobTitle
                        }
                      >
                        {jobTitle} {company ? `at ${companyDisplayName}` : null }
                      </Typography>
                      <ContainerForSVG>
                        <img src={LocationIcon} alt="LocationIcon" />
                        <Typography
                          variant="body1"
                          className={classes.location}
                        >
                          {location && location.location}
                        </Typography>
                      </ContainerForSVG>
                    </Grid>
                    <Hidden mdDown implementation="js">
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={
                          averageRating != undefined &&
                          averageRating != null &&
                          averageRating > 0
                            ? classes.likesButtonContainerLessPaddingTop
                            : classes.likesButtonContainer
                        }
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-end"
                          justify="space-between"
                          className={
                            averageRating != undefined &&
                            averageRating != null &&
                            averageRating > 0
                              ? classes.likesButtonContainerNoPaddingTop
                              : classes.likesButtonContainer
                          }
                        >
                          <Grid item xs={5}>
                            <AdviserLikes
                              amountOfLikes={numberOfLikes}
                              averageRating={averageRating}
                              isAdviserPage={true}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Grid
                              container
                              alignItems="flex-end"
                              justify="flex-end"
                            >
                              <Grid item>
                                <Hidden mdDown implementation="js">
                                  <Dialog
                                    dashboardId={dashboardId}
                                    buttonText={
                                      dictionary.nodes.find(
                                        (x) =>
                                          x.key ===
                                          "adviser_profile_contact_button_text"
                                      ).value
                                    }
                                  />
                                </Hidden>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid
                      container
                      lg={3}
                      md={12}
                      sm={12}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      className={classes.contactMeButtonContainer}
                    >
                      <Hidden lgUp implementation="js">
                        <AdviserLikesMobile
                          amountOfLikes={numberOfLikes}
                          averageRating={averageRating}
                        />

                        <Dialog
                          dashboardId={dashboardId}
                          buttonText={
                            dictionary.nodes.find(
                              (x) =>
                                x.key === "adviser_profile_contact_button_text"
                            ).value
                          }
                          halfButtonPaddingTop={true}
                        />
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>

                <Typography variant="h2" className={classes.aboutMe}>
                  {
                    dictionary.nodes.find(
                      (x) => x.key === "adviser_profile_biography_title"
                      ).value
                    }
                  </Typography>
                  {_rawBio && <PortableText blocks={_rawBio} />}
                </Grid>

                <Grid className={classes.mobileFullwidth}>
                  <Grid item sm={0} className={classes.sidebar}>
                    <Typography variant="h4" className={classes.sidebarTitle}>
                      {
                        dictionary.nodes.find(
                          (x) => x.key === "adviser_profile_side_information_title"
                      ).value
                  }
                </Typography>

                <Grid className={classes.sidebarBlock}>
                  <Typography variant="body1" className={classes.sidebarSubtitle}>
                    {
                      dictionary.nodes.find(
                        (x) =>
                          x.key ===
                          "adviser_profile_side_information_specialisation_text"
                      ).value
                    }
                  </Typography>
                  {specialisations.map(({ specialisation }) => (
                    <Grid key={specialisation} className={classes.sidebarMenuItem}>
                      <ContainerForSVG>
                        <img
                          src={TickYesIcon}
                          alt="TickYesIcon"
                          className={classes.iconSidebar}
                        />{" "}
                        <Typography variant="body2" className={classes.sidebarText80Percent}>
                          {specialisation}
                        </Typography>
                      </ContainerForSVG>
                    </Grid>
                  ))}
                </Grid>

                <Grid className={classes.sidebarBlock}>
                  <Typography variant="body1" className={classes.sidebarSubtitle}>
                    {
                      dictionary.nodes.find(
                        (x) =>
                          x.key ===
                          "adviser_profile_side_information_qualification_text"
                      ).value
                    }
                  </Typography>
                  {qualification.map(({ qualification }) => (
                    <Grid key={qualification} className={classes.sidebarMenuItem}>
                      <ContainerForSVG>
                        <img
                          src={TickYesIcon}
                          alt="TickYesIcon"
                          className={classes.iconSidebar}
                        />{" "}
                        <Typography variant="body2" className={classes.sidebarText80Percent}>
                          {qualification}
                        </Typography>
                      </ContainerForSVG>
                    </Grid>
                  ))}
                </Grid>

                {!hideFeeStructure && <Grid className={classes.sidebarBlock}>
                  <Typography variant="body1" className={classes.sidebarSubtitle}>
                    {
                      dictionary.nodes.find(
                        (x) => x.key === "adviser_profile_side_information_fee_text"
                      ).value
                    }
                  </Typography>
                  {feeStructure.map((text) => (
                    <Grid key={text} className={classes.sidebarMenuItem}>
                      <ContainerForSVG>
                        <img
                          src={TickYesIcon}
                          alt="TickYesIcon"
                          className={classes.iconSidebar}
                        />{" "}
                        <Typography variant="body2" className={classes.sidebarText80Percent}>
                          {text}
                        </Typography>
                      </ContainerForSVG>
                    </Grid>
                  ))}
                </Grid>}
                </Grid>
                <Grid item sm={0} className={classes.itemsRightContainer}>
                {awards && awards.length > 0 &&
                  <Grid item sm={0} className={classes.sideBarAwards}>
                    <PeopleAward awardTitle={awardTitle} awards={awards} awardImage={awardImage} />
                  </Grid>
                }
                {company &&
                  <Grid item sm={0} className={classes.sideBarCompany}>
                    <AdviserCompany companyDisplayName={companyDisplayName} companyLogo={companyLogo} linkHref={fullCompanySlug} />
                  </Grid>
                }
                </Grid>


              {
                widgets.length > 0 && (
                  <Grid item sm={0} className={classes.widgetsContainer}>
                    <WidgetComponent widgets={widgets} />
                  </Grid>
                )}
              </Grid>
              <Grid container className={classes.contactRateContainer}>
                <Grid item className={classes.mobileSixtyFive}>
                  <Dialog
                    dashboardId={dashboardId}
                    buttonText={
                      dictionary.nodes.find(
                        (x) => x.key === "adviser_profile_contact_button_text"
                      ).value
                    }
                    pinkCta={true}
                  />
                </Grid>
                <Grid
                  item
                  direction="row"
                  alignItems="center"
                  justify="center"
                  className={classes.mobileSixtyFive}
                >
                  <RateAdviserDialog
                    buttonText={
                      dictionary.nodes.find(
                        (x) => x.key === "adviser_profile_rate_button_text"
                      ).value
                    }
                    dashboardId={dashboardId}
                    adviserName={name}
                  />
                </Grid>
              </Grid>
              {questions.length > 0 && (
                <Typography variant="h2" className={classes.aboutMe}>
                  {
                    dictionary.nodes.find(
                      (x) => x.key === "adviser_profile_questions"
                    ).value
                  }
                </Typography>
              )}
              <FilteredQuestionsAdviser dashboardId={dashboardId} />
            </Grid>
           </Grid>
          <Container />
          <Hidden smDown implementation="js">
            <AdviserPageReviews
              dashboardId={dashboardId}
              dictionary={dictionary}
              itemsPerPage={3}
            />
          </Hidden>
          <Hidden mdUp implementation="js">
            <AdviserPageReviews
              dashboardId={dashboardId}
              dictionary={dictionary}
              itemsPerPage={2}
            />
          </Hidden>
          <Hidden smDown implementation="js">
            <Grid item className={classes.adContent}>
              <WidgetComponent widgets={widgets} target="bottom" />
            </Grid>
          </Hidden>
        </Container>
        <Grid className={classes.disclaimerFlexContainer} md={10} xs={11} item justify="center">
          <FooterDisclaimer />
        </Grid>

      </ContainerForPages>
    </article>
  );
}

export default BlogPost;
